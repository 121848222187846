import React, { useEffect, useState } from "react";
import "./App.css";

const quotes = [
  "We got listed on coingecko yesterday and we're not x100 yet, it's over.",
  "Not FUD btw",
  '*Project just launched* "did you hear back from binance yet?"',
  "So what's next for this token?",
  "Why are you doing X instead of Y?",
  "Just giving some advice is all..",
  "I can't see this going anywhere without a major CEX",
  "Never says anything that's not soft FUD",
  "Just curious",
  "Demands hourly updates on everything devs are doing",
  "Why did you go with a medium exchange instead of robinhood?",
];

interface QuotePosition {
  x: number;
  y: number;
  dx: number;
  dy: number;
  width: number; // Added to keep track of each quote's width
}

function App() {
  const [quotePositions, setQuotePositions] = useState<QuotePosition[]>(
    []
  );
  const contractAddress =
    "BDvTX3sDgq4egFs6kkD8Ur132G65HYwrVM1U6e1KfSaU";
  const launch = true;

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
  };

  useEffect(() => {
    const initialPositions = quotes.map((quote) => {
      const maxWidth = 300;
      const textWidth = Math.min(maxWidth, quote.length * 10);
      return {
        x: Math.random() * (window.innerWidth - textWidth),
        y: Math.random() * (window.innerHeight - 50),
        dx: (Math.random() - 0.5) * 2,
        dy: (Math.random() - 0.5) * 2,
        width: textWidth,
      };
    });

    setQuotePositions(initialPositions);

    const moveQuotes = () => {
      setQuotePositions((currentPositions) =>
        currentPositions.map((pos) => {
          let newX = pos.x + pos.dx;
          let newY = pos.y + pos.dy;

          // Adjusted to consider the width of the quote for bouncing
          if (newX < 0 || newX + pos.width > window.innerWidth) {
            pos.dx *= -1;
          }
          // Adjusted to prevent going under the footer by considering a safe area
          if (newY < 0 || newY > window.innerHeight - 100) {
            // Assuming footer height around 50px
            pos.dy *= -1;
          }

          return { ...pos, x: pos.x + pos.dx, y: pos.y + pos.dy };
        })
      );
    };

    const intervalId = setInterval(moveQuotes, 20);

    return () => clearInterval(intervalId);
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {});
  };

  // clickable contract (copy)
  // button for pump.fun / dexscreener (based on launch boolean)
  // button for twitter
  // button for telegram

  return (
    <div className="App flex flex-col min-h-screen overflow-hidden">
      <main
        className="flex-grow relative"
        style={{ marginBottom: "0px" }}
      >
        {quotes.map((quote, index) => (
          <p
            key={index}
            style={{
              position: "absolute",
              left: quotePositions[index]?.x,
              top: quotePositions[index]?.y,
              color: "black",
              fontSize: "1.5rem",
              maxWidth: "300px",
              zIndex: 10,
            }}
          >
            {quote}
          </p>
        ))}
      </main>
      <div className="absolute top-0 left-0 m-4 p-4 bg-gray-200 bg-opacity-80 rounded-lg z-20">
        <h2 className="text-lg font-bold">Soft Fudder ($FUDD)</h2>

        <button
          onClick={() => copyToClipboard(contractAddress)}
          onMouseDown={handleMouseDown}
          className="block p-2 text-[#f70000] text-center transition duration-150 ease-in-out transform hover:scale-105 focus:scale-95"
        >
          ca: {contractAddress}
        </button>
        
        <a
          href={
            !launch
              ? "https://www.pump.fun/BDvTX3sDgq4egFs6kkD8Ur132G65HYwrVM1U6e1KfSaU"
              : "https://dexscreener.com/solana/BDvTX3sDgq4egFs6kkD8Ur132G65HYwrVM1U6e1KfSaU"
          }
          target="_blank"
          rel="noopener noreferrer"
          onMouseDown={handleMouseDown}
          className="block p-2 text-[#f70000] transition duration-150 ease-in-out transform hover:scale-105 focus:scale-95"
        >
          {!launch ? "Pump.fun" : "Dexscreener"}
        </a>
        <a
          href="https://twitter.com/softfudderSOL"
          target="_blank"
          rel="noopener noreferrer"
          onMouseDown={handleMouseDown}
          className="block p-2 text-[#f70000] transition duration-150 ease-in-out transform hover:scale-105 focus:scale-95"
        >
          Twitter
        </a>
        <a
          href="https://t.me/+Kz1CnpaMWKdhMmQx"
          target="_blank"
          rel="noopener noreferrer"
          onMouseDown={handleMouseDown}
          className="block p-2 text-[#f70000] transition duration-150 ease-in-out transform hover:scale-105 focus:scale-95"
        >
          Telegram
        </a>
      </div>
      <footer
        className=""
        style={{ zIndex: 5, background: "transparent" }}
      >
        <img
          src="/fudder.png"
          alt="Descriptive Alt Text"
          className="mx-auto"
        />
      </footer>
    </div>
  );
}

export default App;
